import classNames from "classnames"
import React from "react"

import { Color } from "../../../constants/V2/color"
import Accordion from "../../elements/V2/Accordion"
import TextButton from "../../elements/V2/Buttons/TextButton"
import ArrowIcon from "../../elements/V2/Icons/ArrowIcon"
import Typography from "../../elements/V2/Typography"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getBorderColorClass } from "@utils/V2/color"

type ItemProps = {
  question: string
  answerText: Storyblok.StoryblokRichtextContent
}

type FaqProps = {
  title: string
  body: string
  linkText: string
  linkUrl: string
  linkColor: Color
  textColor: Color
  items: ItemProps[]
}

const Faq = ({
  title,
  body,
  linkText,
  linkUrl,
  linkColor,
  items,
  textColor,
  ...props
}: FaqProps) => {
  const { animationRefCallback: animateRefContent } = useScrollAnimateSelf(true)
  const { animationRefCallback: animateRefItems } = useScrollAnimateSelf(true)

  return (
    <>
      <div
        ref={animateRefContent}
        className={classNames(
          "col-span-4 flex flex-col gap-16-v2 sm-v2:col-span-2 md-v2:col-span-3 md-v2:gap-24-v2 md-v2:px-32-v2 lg-v2:col-span-6 lg-v2:gap-32-v2 lg-v2:px-24-v2"
        )}
        {...props}
      >
        <Typography
          font="grotesk"
          weight="medium"
          color={textColor}
          size="h1"
          element="h2"
          text={title}
        />
        <Typography
          font="grotesk"
          weight="book"
          color={textColor}
          size="body-lg"
          text={body}
          className="mb-16-v2 md-v2:mb-24-v2 lg-v2:mb-32-v2"
        />
        <TextButton
          text={linkText}
          linkUrl={linkUrl}
          color={linkColor}
          style="icon"
          icon={<ArrowIcon />}
          className="mb-8-v2"
        />
      </div>
      <div
        ref={animateRefItems}
        className={classNames(
          "col-span-4 flex flex-col gap-24-v2 sm-v2:col-span-2 md-v2:col-span-3 md-v2:gap-32-v2 lg-v2:col-span-6 lg-v2:gap-32-v2"
        )}
        {...props}
      >
        {items.map(({ question, answerText }, index) => (
          <div key={`accordion-${index}`}>
            <Accordion
              key={index}
              title={question}
              iconColor={linkColor}
              textColor={textColor}
              body={answerText}
            />

            <hr
              className={classNames(
                "mt-16-v2 opacity-30",
                getBorderColorClass(textColor)
              )}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default Faq
