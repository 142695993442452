import classNames from "classnames"
import { renderRichText } from "gatsby-source-storyblok"
import React, { ComponentProps, useState } from "react"

import AccordionPlusIcon from "./Icons/AccordionPlusIcon"
import Typography from "./Typography"
import AccordionArrowIcon from "../../../../src/components/elements/V2/Icons/AccordionArrowIcon"
import { Color } from "../../../constants/V2/color"

import { getTextColorClass } from "@utils/V2/color"

type Props = {
  title: string
  body?: string | Storyblok.StoryblokRichtextContent
  textColor: Color
  iconColor: Color
  icon?: "arrow" | "plus"
  titleFontWeight?: ComponentProps<typeof Typography>["weight"]
  contentLeftPadding?: boolean
  children?: React.ReactNode
}

const Accordion = ({
  title,
  textColor,
  body,
  iconColor,
  icon = "plus",
  titleFontWeight = "medium",
  contentLeftPadding = false,
  children,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation(), setIsOpen(!isOpen)
  }

  return (
    <div
      className="grid cursor-pointer grid-cols-[1fr_max-content] gap-x-16-v2"
      onClick={onClick}
    >
      <Typography
        font="grotesk"
        color={textColor}
        text={title}
        size="body-lg"
        weight={titleFontWeight}
      />

      {icon === "plus" ? (
        <AccordionPlusIcon color={iconColor} open={isOpen} />
      ) : (
        <AccordionArrowIcon open={isOpen} color={iconColor} />
      )}

      <div
        className={classNames(
          "col-span-2 max-h-0 flex-nowrap overflow-hidden pt-16-v2 transition-height duration-200 ease-in-out",
          { "max-h-0": !isOpen },
          { "max-h-full": isOpen },
          { "pl-10-v2 sm-v2:pl-20-v2": contentLeftPadding }
        )}
      >
        {typeof body !== "string" ? (
          <div
            className={classNames(
              getTextColorClass(textColor),
              "text-responsive-body-md-v2 font-grotesk font-normal",
              "space-y-20-v2 [&_a]:text-blue-v2"
            )}
            dangerouslySetInnerHTML={{ __html: renderRichText(body) }}
          />
        ) : (
          <Typography
            text={body}
            font="grotesk"
            size="body-md"
            weight="book"
            color={textColor}
          />
        )}
        {children}
      </div>
    </div>
  )
}

export default Accordion
